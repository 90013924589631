header a {
  font-size: 20px;
  margin: 0px 10px 0px 10px;
  font-weight: 600;
  font-family: $navfont;
}

#left-nav {
  margin-top: 5px;
}

#left-nav :last-child {
  margin-right: 20px;
}

#nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 0px 10px;
}

#right-nav {
  display: flex;
  flex-direction: row;
  position: relative;
}

#right-nav div {
  margin-right: 10px;
}

#hamburger {
  display: none;
}

#socialiconmenu {
  display: none;
  position: absolute;
  top: 60px;
  animation: slidein 0.5s;
  z-index: 2;
}

#socialiconmenu a {
  cursor: pointer;
}

@keyframes slidein {
  from {
    margin-top: -50px;
    opacity: 0;
    height: 0px;
  }

  to {
    margin-top: 0px;
    opacity: 100%;
    height: 100%;
  }
}

.footer {
  height: 50px;
  background-color: $moduleopacity;
}

$wrapheight: calc(100vh - 300px);

@media only screen and (max-width: 900px) {
  .module, #aboutshowcase .module {
    height: auto;
    margin-left: 20px;
    padding-bottom: 10px;
  }

  #modwrapper {
    width: 90vw;
  }

  #modwrapper img {
    height: 200px;
    width: 90vw;
    margin-right: 0px;
    float: none;
  }

  .bodywrap {
    -webkit-background-size: 200vw;
    -moz-background-size: 200vw;
    -o-background-size: 200vw;
    background-size: 200vw;
  }

  h1 {
    font-size: 80px;
  }

  #titlewrap h2 {
    font-size: 32px;
  }

  #spacer {
    height: 35vh
  }

  section#img-txt {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #img-txt p {
    width: 80vw;
  }

  #imgdiv {
    margin-left: 20px;
    margin-bottom: 20px;
  }

  #aboutshowcase #modwrapper img {
    width: 90vw;
    height: 200px;
  }
}

@media only screen and (max-width: 600px) {
  header a {
    font-size: 18px;
    margin: 5px;
  }

  #hamburger {
    display: block;
    z-index: 2;
  }

  #nav #socialicon {
    display: none;
  }

  #socialiconmenu {
    display: block;
  }

  h1 {
    font-size: 55px;
  }

  #right-nav div {
    margin-right: 3px;
  }

  #left-nav :last-child {
    margin-right: 10px;
  }

  #titlewrap h2 {
    font-size: 25px;
  }

  #titlewrap {
    height: $wrapheight;
  }

  label {
    top: -27px;
    left: 12px;
    color: $titlecolor;
    padding: 5px;
    z-index: 1;
  }

  #imgdiv img {
    height: 250px;
    width: 250px;
  }

  .module-wrapper {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 390px) {
  h1 {
    font-size: 43px;
  }

  h2 {
    font-size: 35px;
  }

  #titlewrap {
    min-width: 300px;
  }

  .bodywrap {
    -webkit-background-size: 220vw;
    -moz-background-size: 220vw;
    -o-background-size: 220vw;
    background-size: 220vw;
  }

  #spacer {
    height: 35vh
  }


  #titlewrap h2 {
    font-size: 20px;
  }

  #rightnav div {
    display: flex;
    flex-direction: column;
  }

  #modwrapper {
    padding-bottom: 20px;
  }

  #imgdiv img {
    height: 200px;
    width: 200px;
  }

  form {
    width: 250px;
  }

  textarea, input {
    width: 220px;
  }
}

@media only screen and (max-width: 300px) {
  h1 {
    font-size: 32px;
  }

  #titlewrap h2 {
    font-size: 14px;
  }

  #left-nav a {
    font-size: 15px;
  }
}
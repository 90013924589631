$blackboard: 'https://live.staticflickr.com/65535/51776507837_74dbbb26d4_n.jpg';
$sfskyline: 'https://hikers-guide.s3.us-west-1.amazonaws.com/HikersGuide_GP2/IMG_7544.jpg';
$clouds: 'https://live.staticflickr.com/65535/51777592253_1feaf70317_n.jpg';
$clouds2: 'https://live.staticflickr.com/65535/51778574217_3a46a12c47_k.jpg';


html {
  background-color: black;
  color: $titlecolor;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  font-family: sans-serif;
  
}

.bodywrap {
  background-image: url($clouds2);
  -webkit-background-size: 100vw;
  -moz-background-size: 100vw;
  -o-background-size: 100vw;
  background-size: 100vw;
  background-attachment: scroll;
}

.bodyscreen {
  background-color: $bodyscreen;
  min-height: 100vh;
}

h1 {
  margin-top: 0px;
  font-family: $titlefont;
  font-size: 110px;
  font-weight: 800;
}

h2 {
  margin-top: 0px;
  font-family: $bodyfont;
  font-size: 45px;
  font-weight: 200;
}

h3 {
  margin-top: 0px;
}

a {
  color: $titlecolor;
  text-decoration: none;
  cursor: pointer;
}

button {
  padding: 10px;
  background-color: $titlecolor;
  border-radius: 10px;
  width: 100%;
  border: 1px solid rgba(0,0,0,0);
  cursor: pointer;
  color: black;
}

iframe {
  display: none;
}

form p {
  font-size: 10px;
  text-align: center;
}

p {
  display: block;
  margin: 0px 0px 0px 0px;
}

textarea {
  height: 200px;
  resize: vertical;
}

img {
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding-top: 150px;
}

.module-wrapper {
  padding-top: 150px;
}

#lowerwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#splashwrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rocket {
  height: 100px;
}

.rocket-container {
  transition: bottom 1s;
  position: relative;
  bottom: 0;
  z-index: 0;
  user-select: none;
  height: 100px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.static-rocket {
  cursor: pointer;
  animation: wiggle 1s;
  animation-iteration-count: infinite;
}

.moving-rocket {
  cursor:default;
}

.static-rocket.clicked {
  bottom: 150vh;
  transition: bottom 2s;
  height: 100px;
}

.static-rocket.clicked .rocket {
  height: 20px;
  transition: height 2s;
}

@keyframes wiggle {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(1px, -1px) rotate(1deg) ; }
  20% { transform: translate(-1px, 1px) rotate(-2deg); }
  30% { transform: translate(-1px, -1px) rotate(1deg); }
  40% { transform: translate(1px, 1px) rotate(1deg); }
  50% { transform: translate(-1px, -1px) rotate(-1deg); }
  60% { transform: translate(1px, 1px) rotate(-1deg); }
  70% { transform: translate(1px, -1px) rotate(1deg) ; }
  80% { transform: translate(-1px, 0px) rotate(-2deg); }
  90% { transform: translate(-1px, -1px) rotate(1deg); }
  100% { transform: translate(0px, 1px) rotate(1deg); }
}

#pagespacer {
  height: 50px;
}

#aboutadrian {
  margin-bottom: 0px;
}
input, textarea {
  font-size: 18px;
  font-family: sans-serif;
  background-color: $wrapperopacity;
  border: 1px solid rgba(0,0,0,0);
  color: white;
  padding: 15px 15px 15px 20px;
  border-radius: 10px;
  width: 300px;
  transition: outline $transitiontxt;
}

textarea {
  display: block;
}

input:focus, textarea:focus {
  background-color: rgba(0,0,0,0.9);
  outline: none;
}

form {
  margin-top: 10px;
}

#inputwrap {
  position: relative;
  margin-bottom: 30px;
}

label {
  position: absolute;
  top: 7px;
  left: 7px;
  padding: 10px;
  z-index: 1;
  font-size: 14px;
  color: rgba($titlecolor, 0.6);
  cursor: text;
  transition: top $transitiontxt, left $transitiontxt, 
      background-color $transitiontxt,
      color $transitiontxt, padding $transitiontxt
}

input:not(:placeholder-shown) ~ label, 
textarea:not(:placeholder-shown) ~ label, 
input:focus ~ label, textarea:focus ~ label, 
input:hover ~ label, textarea:hover ~ label, 
label:hover {
  top: -27px;
  left: 12px;
  color: $titlecolor;
  padding: 5px;
  z-index: 1;
}




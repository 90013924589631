#img-txt {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
}

.about-wrap {
  margin-bottom: 30px;
}

#lowerwrapper.page {
  padding-top: 50px;
  background-color: $wrapperopacity;
  padding-bottom: 50px;
}

#imgdiv {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#txtdiv {
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
}

#txtdiv p {
  padding: 10px;
}

#img-txt img {
  border-radius: 200px;
  height: 200px;
  width: 200px;
}

#aboutshowcase {
  background: none;
}

#aboutwrapper {
  padding-top: 150px;
}

button h2 {
  font-size: 25px;
  padding: 0px;
  margin: 0px;
}

#socialabout {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#socialabout #socialicon {
  margin-left: 5px;
  margin-right: 5px;
}


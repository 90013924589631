.wrapper {
  width: 100vw;
  min-height: 200px;
}

#titlewrap {
  text-align: center;
  line-height: 0.8;
  height: calc(100vh - 200px);
  min-height: 500px;
  color: $titlecolor;
  z-index: 1;
}

#spacer {
  height: 35vh;
  z-index: -1;
}

#titlewrap h1 {
  display: block;
  margin: 0 auto;
}

#titlewrap h2 {
  padding-right: 10px;
  display: block;
  margin: 0 auto;
  margin-top: 5px;
  font-family: $subtitlefont;
}

.lower-splash {
  background-color: $wrapperopacity;
  margin-top: 50px;
  padding-bottom: 50px;
}

.animwrapper {
  margin-bottom: 70px;
}


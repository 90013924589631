$titlefont: 'Staatliches';
$subtitlefont: 'Caveat';
$navfont: 'Staatliches';
$bodyfont: 'Caveat';
$titlecolor: #fdfaec;
$titlelabel: #fdfaec73;
$modulecolor: rgb(26, 26, 26);
$moduleopacity: rgba(44, 33, 98, 0.9);
$wrapperopacity: rgba(61, 48, 124, 0.8);
$bodyimagescreen: rgba(#41447c, 0.3);
$bodyscreen: rgba(#5458a5, 0.55);
$transitiontxt: 0.2s ease-out;

:export {
  tf: $titlefont;
  sf: $subtitlefont;
  tc: $titlecolor;
}

@import 'splash.scss';
@import 'general.scss';
@import 'showcase.scss';
@import 'header.scss';
@import 'form.scss';
@import 'responsive.scss';
@import 'about.scss';
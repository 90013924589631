$moduleheight: 200px;
$modulewidth: 80vw;
$showcasewidth: 40vw;
$imagewidth: 30vw;
$imageheight: 250px;
$aboutheight: 290px;
$aboutwidth: 300px;

#showcase h3 {
  text-align: center;
  font-size: 30px;
  font-family: $subtitlefont;
}

.module {
  width: $modulewidth;
  height: $moduleheight;
  border-radius: 10px;
}

.module h4 {
  font-size: 30px;
  font-family: $titlefont;
  margin-bottom: 10px;
  margin-top: 20px;
  padding-right: 20px;
}

.module p {
  padding-right: 20px;
  margin-bottom: 10px;
}

#modwrapper {
  width: $modulewidth;
  overflow: hidden;
  border-radius: 3px;
  background-color: $moduleopacity;
  margin: 20px;
}

#modwrapper img {
  float: left;
  width: $imagewidth;
  min-width: 300px;
  height: $imageheight;
  object-fit: cover;
  margin-right: 20px;
  min-width: 320px;
}

#showcase.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



